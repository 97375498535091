import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AboutUsItem from '../common/aboutUsItem';
import Info, { ButtonWrapper, InfoTitle } from '../common/info';
import {
  LAPTOP, TABLET, media, CONTAINER_WIDTH, CONTAINER_LAPTOP_WIDTH,
} from '../../constants/screens';
import { TURQUOISE, WHITE } from '../../constants/colors';

const Introduction = ({
  image: { file: { localFile: { childImageSharp: { sizes } } } },
  title,
  subTitle,
  description,
  button: { url, text },
  scrollTo,
}) => (
  <Container>
    <Content>
      <AboutUsItem
        image={sizes}
        imgAltAttr="SlashData - reports"
        maxImageWidth="770px"
        reversed
      >
        <Info
          title={title}
          subTitle={subTitle}
          text={description}
          titleColor={WHITE}
          subTitleColor={TURQUOISE}
          subTitleFontSize="40px"
          withButton
          buttonText={text}
          link={url}
          reversed
          pageName="reports"
          switchTitleAndSubtitle
          onButtonClick={() => {
            setTimeout(() => scrollTo('subscriptions'), 300);
          }}
        />
      </AboutUsItem>
    </Content>
  </Container>
);

Introduction.propTypes = {
  image: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.shape().isRequired,
  scrollTo: PropTypes.func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(126deg, #2a159a, #2a159a 46%, #2b55c5);
  padding: 300px 5% 150px 5%;
  
  @media screen and (max-width: ${TABLET}) {
    padding: 200px 5% 150px 5%;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: 1600px) {
    padding: 225px 5% 75px 5%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  & ${ButtonWrapper} {
    margin-top: 20px;
    
    ${media.tablet`
       padding: 0;
       margin-top: 0px;
    `}
    
    ${media.mobile`
       padding: 0;
       margin-top: 0px;
    `}
  }
  
  & h1 {
    margin-bottom: 15px
  }
  
  & ${InfoTitle} {
    font-size: 40px;

    ${media.mobile`
      font-size: 24px;
    `}
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      font-size: 26px;
    }
  }
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
  }
  
  > div {
    width: 100%;
    
    @media screen and (min-width: ${TABLET}) {
      > div:first-child {
        > * {
          text-align: left;
          align-items: flex-start;
        }
        
        > div > div {
          justify-content: flex-start;
        }
      }
    }
  }
`;

export default Introduction;
