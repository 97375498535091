import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { graphql } from 'gatsby';
import Introduction from '../components/reportsPage/introduction';
import { groupItemsByCategory } from '../utils/keystone/data';
import withCustomLayout from '../enhancers/withCustomLayout';
import withStickyHeader from '../enhancers/withStickyHeader';
import { LIGHT_SCHEME, WHITE } from '../constants/colors';
import withChildRoutes from '../enhancers/withChildRoutes';
import BrochureRequest from '../components/brochureRequestPage';
import ImageSection from '../components/common/imageSection';
import Questions from '../components/common/questions';
import { media } from '../constants/screens';
import Section from '../components/common/section';
import Categories from '../components/common/categories';
import ItemGroups from '../components/common/itemGroups';
import withScroll from '../enhancers/withScroll';
import withClass from '../enhancers/withClass';

const Reports = (props) => {
  const {
    data: {
      page: { introduction, sections = [] },
      categories: { edges: categories },
      reports: { edges: reports },
    },
    scrollTo,
  } = props;

  const questions = sections.filter(({ meta }) => meta && meta.includes('questions'));
  const imageSection = sections.filter(({ meta }) => meta && meta.includes('image'));

  return (
    <div>
      <Introduction
        {...introduction}
        scrollTo={scrollTo}
      />
      {imageSection && (
        <ImageSection
          bg={WHITE}
          {...imageSection[0]}
        />
      )}
      {questions && (
        <Questions
          {...questions[0]}
          css={`padding-bottom: 100px; ${media.tablet`padding-bottom: 0; `}`}
        />
      )}
      {sections[2] && (
        <Section
          {...sections[2]}
          bg={WHITE}
          css={`padding-bottom: 100px; ${media.tablet`padding-bottom: 0; `}`}
        />
      )}
      {sections[3] && (
        <Section
          {...sections[3]}
          bg={WHITE}
          align="center"
          css="padding-bottom: 0;"
        />
      )}
      <Categories categories={categories} />
      <ItemGroups groups={groupItemsByCategory(categories, reports)} />
    </div>
  );
};

Reports.propTypes = {
  scrollTo: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
};

export default compose(
  withChildRoutes({
    defaultRoute: '/reports',
    childRoutes: [
      { path: '/reports/:slug', component: BrochureRequest },
    ],
  }),
  withStickyHeader(),
  withCustomLayout({ scheme: LIGHT_SCHEME }),
  withScroll({
    sections: {
      subscriptions: [5, 1],
    },
  }),
  withClass,
)(Reports);

// eslint-disable-next-line
export const query = graphql`
  query ReportsPageQuery {
    caseBackground: file(relativePath: { regex: "/case-background.png/" }) {
      childImageSharp {
        original {
          src
        }
      }
    }
    page(url: { regex: "/reports/" }) {
      introduction {
        title
        subTitle
        description
        button {
          text
          url
        }
        image {
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 800) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
      sections {
        id: _id
        title
        meta
        description
        content {
          id: _id
          description {
            md
          }
        }
        image {
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 700) {
                  ...GatsbyImageSharpSizes_noBase64
                }
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    reports: allReport(sort: { fields: [ publishedAt ], order: [ DESC ] }) {
      edges {
        node {
          id
          title
          subtitle
          slug
          type
          meta {
            description
          }
          excerpt
          publishedAt(formatString: "MMMM YYYY")
          categories {
            id: _id
            name
            slug
          }
          cover {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          extra {
            keyQuestions {
              html
            }
            methodology {
              html
            }
            tableOfContents {
              html
            }
          }
          sample_report {
            url,
          }
        }
      }
    }
    categories: allReportCategory {
      edges {
        node {
          id: _id
          name
          slug
          image {
            file {
              localFile {
                publicURL
              }
            }
          }
          description {
            md
          }
        }
      }
    }
  }
`;
